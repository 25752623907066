const Main = () => {


    return(
        <>
           Mobile
        </>
    )
}

export default Main;